import { FunctionComponent } from "react";

const SpliceDesktop: FunctionComponent = () => {
  return (
    <div className="relative bg-white w-full h-[5791px] overflow-hidden text-left text-xs text-white font-shne-breit-test">
      <img
        className="absolute top-[3354px] left-[0px] w-[1440px] h-[1686.71px] object-cover"
        alt=""
        src="/vector-7@2x.png"
      />
      <div className="absolute top-[3354px] left-[0px] w-[1440px] h-[1686.71px]">
        <img
          className="absolute h-[129.45%] w-[148.33%] top-[51.88%] right-[-36.11%] bottom-[-81.32%] left-[-12.22%] max-w-full overflow-hidden max-h-full object-cover opacity-[0.12]"
          alt=""
          src="/patterns@2x.png"
        />
      </div>
      <div className="absolute top-[4869px] left-[80px] w-[1280px] h-[232px]">
        <div className="absolute top-[0px] left-[0px] rounded-[22px] [background:linear-gradient(95.31deg,_#a659ff,_#7178ff_36.98%,_#2148ff_65.1%,_#ff3517)] w-[1280px] h-[232px]" />
        <div className="absolute top-[153px] left-[548px] rounded-86xl flex flex-row items-start justify-start py-[11px] px-[35px] border-[1px] border-solid border-white">
          <div className="relative">JOBS AT SPLICE</div>
        </div>
        <div className="absolute top-[43px] left-[365px] text-[36px] leading-[108%] text-center inline-block w-[549px]">
          Come change the way people make music
        </div>
      </div>
      <div className="absolute top-[5187px] left-[80px] w-[1222px] h-[473px] text-smi text-dimgray-100">
        <img
          className="absolute top-[0px] left-[0px] w-[115px] h-[35px] object-cover mix-blend-normal"
          alt=""
          src="/logolockuplight-3x-2@2x.png"
        />
        <div className="absolute top-[278px] left-[500px] w-[153px] h-[116px]">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400 inline-block w-[115px] h-[19px]">
            BEATMAKER
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%] inline-block w-[153px] h-[19px]">
            Create Your Own Beat
          </div>
          <div className="absolute top-[67px] left-[0px] leading-[142.7%] inline-block w-[71px] h-[19px]">
            Wakapella
          </div>
          <div className="absolute top-[97px] left-[0px] leading-[142.7%] inline-block w-[72px] h-[19px]">
            Just Blaze
          </div>
        </div>
        <div className="absolute top-[278px] left-[720px] w-[180px] h-[195px]">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400 inline-block w-[115px] h-[19px]">
            BLOG
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%] inline-block w-[153px] h-[19px]">
            Latest Posts
          </div>
          <div className="absolute top-[67px] left-[0px] leading-[142.7%] inline-block w-[180px]">
            Using Soundtoys Decapitator
          </div>
          <div className="absolute top-[116px] left-[0px] leading-[142.7%]">
            Mastering 101
          </div>
          <div className="absolute top-[146px] left-[0px] leading-[142.7%]">
            Producing Future Beats
          </div>
          <div className="absolute top-[176px] left-[0px] leading-[142.7%]">
            Belonging at Splice
          </div>
        </div>
        <div className="absolute top-[278px] left-[280px] w-[130px] h-44">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400">
            PLUGINS
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%]">
            Top VSTs
          </div>
          <div className="absolute top-[67px] left-[0px] leading-[142.7%]">
            Serum
          </div>
          <div className="absolute top-[97px] left-[0px] leading-[142.7%]">
            Rent to Own
          </div>
          <div className="absolute top-[127px] left-[0px] leading-[142.7%]">
            Free Plugins
          </div>
          <div className="absolute top-[157px] left-[0px] leading-[142.7%]">
            Top Manufacturers
          </div>
        </div>
        <div className="absolute top-[8px] left-[280px] w-[180px] h-[193px]">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400">
            STUDIO
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%]">
            Features
          </div>
          <div className="absolute top-[159px] left-[0px] inline-block w-[180px]">
            Collaborating with Baewatch
          </div>
          <div className="absolute top-[105px] left-[0px] inline-block w-[180px]">
            Managing Your Production Library
          </div>
          <div className="absolute top-[71px] left-[0px] leading-[142.7%]">
            About Splice Studio
          </div>
        </div>
        <div className="absolute top-[8px] left-[720px] w-[180px] h-[170px]">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400">
            SOUNDS
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%]">
            Features
          </div>
          <div className="absolute top-[125px] left-[0px] inline-block w-[180px]">
            deadmau5s Chimaera
          </div>
          <div className="absolute top-[153px] left-[0px] inline-block w-[180px]">
            KSHMR Vol. 2
          </div>
          <div className="absolute top-[97px] left-[0px] inline-block w-[180px]">
            How Jauz Uses Sounds
          </div>
          <div className="absolute top-[67px] left-[0px] leading-[142.7%]">
            Catalog
          </div>
        </div>
        <div className="absolute top-[8px] left-[500px] w-[146px] h-[206px]">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400">
            COMMUNITY
          </div>
          <div className="absolute top-[37px] left-[0px] leading-[142.7%]">
            Ableton Live Projects
          </div>
          <div className="absolute top-[97px] left-[0px] leading-[142.7%]">
            Logic Pro X Projects
          </div>
          <div className="absolute top-[127px] left-[0px] leading-[142.7%]">
            Garageband Projects
          </div>
          <div className="absolute top-[157px] left-[0px] leading-[142.7%]">
            Remix Contests
          </div>
          <div className="absolute top-[187px] left-[0px] leading-[142.7%]">
            Tiestos Secrets
          </div>
          <div className="absolute top-[67px] left-[0px] leading-[142.7%]">
            FL Studio Projects
          </div>
        </div>
        <div className="absolute top-[48px] left-[0px] text-3xs leading-[142.7%]">
          2021 Splice.com All Rights Reserved
        </div>
        <div className="absolute top-[8px] left-[990px] w-[232px] h-[130px] text-3xs">
          <div className="absolute top-[0px] left-[0px] text-mini text-gray-400">
            FIND US ON SOCIAL
          </div>
          <div className="absolute top-[91px] left-[0px] leading-[142.7%]">
            Terms of Use
          </div>
          <div className="absolute top-[91px] left-[101px] leading-[142.7%]">
            Privacy Policy
          </div>
          <div className="absolute top-[91px] left-[205px] leading-[142.7%]">
            Jobs
          </div>
          <div className="absolute top-[116px] left-[0px] leading-[142.7%]">
            Contact
          </div>
          <div className="absolute top-[116px] left-[76px] leading-[142.7%]">
            Help
          </div>
          <img
            className="absolute top-[43px] left-[33px] w-[15.98px] h-[16.65px] overflow-hidden object-cover"
            alt=""
            src="/twitter--negative@2x.png"
          />
          <img
            className="absolute top-[43px] left-[66.4px] w-[16.65px] h-[16.65px] overflow-hidden object-cover"
            alt=""
            src="/instagram--negative@2x.png"
          />
          <img
            className="absolute top-[43px] left-[100.46px] w-[16.65px] h-[16.65px] overflow-hidden object-cover"
            alt=""
            src="/youtube--negative@2x.png"
          />
          <img
            className="absolute top-[43px] left-[0px] w-4 h-4 overflow-hidden object-cover"
            alt=""
            src="/facebook--negative@2x.png"
          />
        </div>
      </div>
      <div className="absolute top-[4387px] left-[82px] w-[1278px] h-[306px] text-3xl">
        <img
          className="absolute top-[154px] left-[328px] w-10 h-10 object-cover"
          alt=""
          src="/iconlybulkunlock@2x.png"
        />
        <div className="absolute top-[209px] left-[0px] leading-[108%]">
          100 royalty free
        </div>
        <div className="absolute top-[209px] left-[328px] leading-[108%]">
          No commitments
        </div>
        <div className="absolute top-[209px] left-[658px] leading-[108%]">
          Yours forever
        </div>
        <div className="absolute top-[209px] left-[988px] leading-[108%]">
          Individual samples
        </div>
        <div className="absolute top-[0px] left-[417px] text-[38px] leading-[108%] text-center">
          As good as it sounds
        </div>
        <div className="absolute top-[252px] left-[0px] text-base leading-[171%] text-gray-100 inline-block w-72">
          Use sounds for anything. They’re cleared for commercial use.
        </div>
        <div className="absolute top-[252px] left-[328px] text-base leading-[171%] text-gray-100 inline-block w-[290px]">
          Cancel your subscription at any time, no questions asked.
        </div>
        <div className="absolute top-[252px] left-[658px] text-base leading-[171%] text-gray-100 inline-block w-[290px]">
          Keep everything you download. Even if you cancel.
        </div>
        <div className="absolute top-[252px] left-[988px] text-base leading-[171%] text-gray-100 inline-block w-[290px]">{`Preview & download individual samples, not just full packs.`}</div>
        <img
          className="absolute top-[154px] left-[0px] w-10 h-10 object-cover"
          alt=""
          src="/iconlybulkvolume-up@2x.png"
        />
        <img
          className="absolute top-[154px] left-[658px] w-10 h-10 object-cover"
          alt=""
          src="/iconlybulkdownload@2x.png"
        />
        <img
          className="absolute top-[154px] left-[988px] w-10 h-10 object-cover"
          alt=""
          src="/iconlybulkplay@2x.png"
        />
      </div>
      <div className="absolute top-[3170px] left-[82px] w-[1570px] h-[1075px] text-lg">
        <div className="absolute top-[414px] left-[0px] text-29xl leading-[108%] inline-block w-[400px]">
          <span>{`What `}</span>
          <span className="text-mediumblue">{`creators `}</span>
          <span>are saying about Splice</span>
        </div>
        <div className="absolute top-[592px] left-[0px] leading-[171%] text-gray-100 inline-block w-[400px]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Augue tellus
          urna, mi velit diam. Turpis diam amet massa id.
        </div>
        <img
          className="relative w-[1074px] h-[1075px] object-cover"
          alt=""
          src="/subtract@2x.png"
        />
        <div className="absolute top-[725px] left-[0px] rounded-86xl bg-mediumblue flex flex-row items-start justify-start py-[11px] px-[35px] text-xs">
          <div className="relative">TRY IT NOW</div>
        </div>
        <div className="absolute top-[330px] left-[561px] w-[290px] h-[216px]">
          <div className="absolute top-[0px] left-[0px] rounded-10xs bg-gray-300 w-[290px] h-[216px] mix-blend-normal" />
          <div className="absolute top-[28px] left-[28px] w-[234px] h-40">
            <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[234px]">
              I can always find what Im looking for on Splice, whether its the
              exact sound I want or just a bit of inspiration.
            </div>
            <div className="absolute top-[127px] left-[50px] text-2xs leading-[108%]">
              Andrew Huang
            </div>
            <div className="absolute top-[143px] left-[50px] text-5xs leading-[108%]">
              Artist
            </div>
            <img
              className="absolute top-[120px] left-[0px] w-10 h-10 object-cover"
              alt=""
              src="/image-9@2x.png"
            />
          </div>
        </div>
        <div className="absolute top-[585px] left-[640px] w-[290px] h-[216px]">
          <div className="absolute top-[0px] left-[0px] rounded-10xs bg-gray-300 w-[290px] h-[216px] mix-blend-normal" />
          <div className="absolute top-[28px] left-[28px] w-[234px] h-40">
            <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[234px]">
              Its been fun to dive into Splices creator community and explore
              tools that support my own creative process.
            </div>
            <div className="absolute top-[127px] left-[50px] text-2xs leading-[108%]">
              Kesha Lee
            </div>
            <div className="absolute top-[143px] left-[50px] text-5xs leading-[108%]">
              Artist
            </div>
            <img
              className="absolute top-[120px] left-[0px] w-10 h-10 object-cover"
              alt=""
              src="/image-9@2x.png"
            />
          </div>
        </div>
        <div className="absolute top-[330px] left-[890px] w-[290px] h-[216px]">
          <div className="absolute top-[0px] left-[0px] rounded-10xs bg-gray-300 w-[290px] h-[216px] mix-blend-normal" />
          <div className="absolute top-[28px] left-[28px] w-[234px] h-40">
            <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[234px]">
              Finally a way to buy plugins that works. By paying a little at a
              time, producers can get legit access to the top VSTs.
            </div>
            <div className="absolute top-[127px] left-[50px] text-2xs leading-[108%]">
              KSHMR
            </div>
            <div className="absolute top-[143px] left-[50px] text-5xs leading-[108%]">
              Artist
            </div>
            <img
              className="absolute top-[120px] left-[0px] w-10 h-10 object-cover"
              alt=""
              src="/image-9@2x.png"
            />
          </div>
        </div>
        <div className="absolute top-[585px] left-[969px] w-[290px] h-[216px]">
          <div className="absolute top-[0px] left-[0px] rounded-10xs bg-gray-300 w-[290px] h-[216px] mix-blend-normal" />
          <div className="absolute top-[28px] left-[28px] w-[234px] h-40">
            <div className="absolute top-[0px] left-[0px] leading-[108%] inline-block w-[234px]">
              I can always find what Im looking for on Splice, whether its the
              exact sound I want or just a bit of inspiration.
            </div>
            <div className="absolute top-[127px] left-[50px] text-2xs leading-[108%]">
              Andrew Huang
            </div>
            <div className="absolute top-[143px] left-[50px] text-5xs leading-[108%]">
              Artist
            </div>
            <img
              className="absolute top-[120px] left-[0px] w-10 h-10 object-cover"
              alt=""
              src="/image-9@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="absolute top-[2653px] left-[139px] w-[1188px] h-[448px] text-29xl text-gray-200">
        <div className="absolute top-[144px] left-[0px] leading-[108%] inline-block w-[464px]">
          <span>{`Stay in `}</span>
          <span className="text-mediumblue">sync</span>
        </div>
        <div className="absolute top-[121px] left-[0px] text-base text-dimgray-300">
          STUDIO
        </div>
        <div className="absolute top-[214px] left-[0px] text-lg leading-[171%] text-gray-100 inline-block w-[400px]">
          Try industry-leading music software for free, pay it off over time and
          own it forever.
        </div>
        <div className="absolute top-[310px] left-[0px] rounded-86xl bg-mediumblue flex flex-row items-start justify-start py-[11px] px-[35px] text-xs text-white">
          <div className="relative">TRY STUDIO</div>
        </div>
        <img
          className="absolute top-[0px] left-[517px] w-[671px] h-[448px] object-cover"
          alt=""
          src="/image-13@2x.png"
        />
      </div>
      <div className="absolute top-[1621px] left-[0px] w-[1440px] h-[1002.5px] text-29xl text-gray-400">
        <img
          className="absolute top-[0px] left-[0px] w-[1440px] h-[1002.5px] object-cover"
          alt=""
          src="/rectangle-11@2x.png"
        />
        <div className="absolute top-[503px] left-[872px] leading-[108%] inline-block w-[423px]">
          <span>{`Build your `}</span>
          <span className="text-mediumblue">digital studio</span>
        </div>
        <div className="absolute top-[483px] left-[872px] text-base text-dimgray-300">
          PLUGINS
        </div>
        <div className="absolute top-[625px] left-[872px] text-lg leading-[171%] text-gray-100 inline-block w-[400px]">
          Try industry-leading music software for free, pay it off over time and
          own it forever.
        </div>
        <div className="absolute top-[721px] left-[872px] rounded-86xl bg-mediumblue flex flex-row items-start justify-start py-[11px] px-[35px] text-xs text-white">
          <div className="relative">TRY GEAR</div>
        </div>
        <div className="absolute top-[calc(50%_-_128.25px)] right-[799px] w-[502px] h-[550px] flex items-center justify-center">
          <img
            className="w-full h-full object-contain absolute left-[0px] top-[10px] [transform:scale(1.08)]"
            alt=""
            src="/logos@2x.png"
          />
        </div>
      </div>
      <div className="absolute top-[1024px] left-[-78.5px] w-[1538.5px] h-[832px]">
        <div className="absolute top-[220px] left-[716.5px] rounded-86xl bg-mediumblue flex flex-row items-start justify-start py-[11px] px-[35px]">
          <div className="relative">TRY SOUNDS</div>
        </div>
        <div className="absolute top-[23px] left-[583.5px] text-29xl leading-[108%] text-gray-200">
          <span>{`Find your `}</span>
          <span className="text-mediumblue">sound</span>
        </div>
        <div className="absolute top-[0px] left-[757.5px] text-base text-dimgray-300">
          SOUNDS
        </div>
        <div className="absolute top-[93px] left-[519.5px] text-lg leading-[171%] text-gray-100 text-center inline-block w-[558px]">
          Preview and download millions of royalty-free samples from top
          producers, artists, and sound designers. Available on desktop, web,
          and mobile.
        </div>
        <div className="absolute top-[331.5px] left-[0px] w-[1538.5px] h-[500.5px] flex items-center justify-center">
          <img
            className="w-full h-full object-contain absolute left-[0px] top-[0px] [transform:scale(1.02)]"
            alt=""
            src="/vector-8@2x.png"
          />
        </div>
        <img
          className="absolute top-[307px] left-[418.5px] w-[760px] h-[499px] object-cover"
          alt=""
          src="/image-12@2x.png"
        />
      </div>
      <div className="absolute top-[796px] left-[194px] w-[1052px] h-[101px] text-smi text-dimgray-100">
        <div className="absolute top-[0px] left-[475px]">FEATURED IN</div>
        <div className="absolute top-[47px] left-[0px] w-[1052px] h-[54px] mix-blend-normal">
          <img
            className="absolute top-[0px] left-[0px] w-[147px] h-[54px] object-cover opacity-[0.29] mix-blend-normal"
            alt=""
            src="/image-1@2x.png"
          />
          <img
            className="absolute top-[3px] left-[905px] w-[147px] h-12 object-cover opacity-[0.29] mix-blend-normal"
            alt=""
            src="/image-2@2x.png"
          />
          <img
            className="absolute top-[0px] left-[679px] w-[147px] h-[54px] object-cover opacity-[0.29] mix-blend-normal"
            alt=""
            src="/image-3@2x.png"
          />
          <img
            className="absolute top-[3px] left-[226px] w-[147px] h-12 object-cover opacity-[0.29] mix-blend-normal"
            alt=""
            src="/image-5@2x.png"
          />
          <img
            className="absolute top-[0px] left-[452px] w-[148px] h-[54px] object-cover opacity-[0.29] mix-blend-normal"
            alt=""
            src="/image-4@2x.png"
          />
        </div>
      </div>
      <div className="absolute top-[0px] left-[-1px] w-[1441px] h-[701.76px] text-lg">
        <img
          className="absolute top-[0px] left-[0px] w-[1441px] h-[635.93px] object-cover"
          alt=""
          src="/rectangle-10@2x.png"
        />
        <img
          className="absolute top-[0px] left-[653.26px] w-[787.74px] h-[701.76px] object-cover mix-blend-normal"
          alt=""
          src="/vector-5@2x.png"
        />
        <div className="absolute top-[341px] left-[81px] leading-[171%] text-dimgray-200 inline-block w-[510px]">
          Royalty-free sounds. Industry-leading software. Endless inspiration.
          Start creating with Splice.
        </div>
        <div className="absolute top-[42px] left-[273px] w-[1088px] h-8 text-3xs">
          <div className="absolute top-[10px] left-[0px] w-[397px] h-[13px] text-gray-200">
            <div className="absolute top-[0px] left-[0px]">STUDIO</div>
            <div className="absolute top-[0px] left-[77px]">COMMUNITY</div>
            <div className="absolute top-[0px] left-[193px]">SOUNDS</div>
            <div className="absolute top-[0px] left-[277px]">PLUGINS</div>
            <div className="absolute top-[0px] left-[363px]">BLOG</div>
          </div>
          <div className="absolute top-[0px] left-[979px] w-[109px] h-8">
            <div className="absolute top-[0px] left-[0px] rounded-86xl bg-gray-500 [backdrop-filter:blur(5px)] w-[109px] h-8" />
            <div className="absolute top-[9px] left-[31px]">SIGN UP</div>
          </div>
          <div className="absolute top-[10px] left-[912px]">LOG IN</div>
        </div>
        <div className="absolute top-[437px] left-[81px] rounded-86xl bg-mediumblue flex flex-row items-start justify-start py-[11px] px-[35px] text-xs">
          <div className="relative">SIGN UP</div>
        </div>
        <img
          className="absolute top-[42px] left-[81px] w-[113px] h-[34px] overflow-hidden object-cover"
          alt=""
          src="/frame@2x.png"
        />
      </div>
      <img
        className="absolute top-[195px] left-[80px] w-[481.16px] h-[122.64px] object-cover"
        alt=""
        src="/group-5@2x.png"
      />
    </div>
  );
};

export default SpliceDesktop;
